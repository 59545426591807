import { FlyoutType, openFlyout } from '@paid-ui/blocks/global-flyout';
import { EmailIcon } from '@paid-ui/icons/email';

import { NavbarIconButton } from '../navbar-button';

export const HelpCenter: React.FC = () => {
  return (
    <NavbarIconButton
      className="group flex flex-col justify-center gap-2 sm:h-full sm:w-full"
      onClick={() =>
        openFlyout(FlyoutType.HELP_CENTER, {
          className: 'w-[752px]',
          showCloseIcon: true,
          closeOnClickOutside: true,
        })
      }
    >
      <EmailIcon className="text-white/70 group-hover:text-white group-focus:text-white sm:h-[18px] sm:w-[18px]" />
      <span className="hidden flex-1 text-xs sm:inline-block">Help</span>
    </NavbarIconButton>
  );
};
