import { UnstyledButton } from '@paid-ui/components/unstyled-button';
import { browserEnvs } from '@paid-ui/env';
import { ArrowLeft2Icon } from '@paid-ui/icons/arrow-left-2';
import { ArrowRight2Icon } from '@paid-ui/icons/arrow-right-2';
import { CloseIcon } from '@paid-ui/icons/close';
import { LocationIcon } from '@paid-ui/icons/location';
import { ZoomInIcon } from '@paid-ui/icons/zoom-in';
import { ZoomOutIcon } from '@paid-ui/icons/zoom-out';
import { type Evidence } from '@paid-ui/schemas/zod/evidence';
import { getGeolocationStatusText, getPreSignedUrl } from '@paid-ui/utils';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { forwardRef, useEffect, useRef, useState } from 'react';
import PrismaZoom from 'react-prismazoom';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';

import { NestedFlyoutType, openNestedFlyout } from '../global-flyout';
import {
  closeEvidencePreview,
  evidencePreviewManager,
  nextEvidence,
  prevEvidence,
  PreviewType,
} from './model';

const DialogRoot = DialogPrimitive.Root;
const DialogPortal = DialogPrimitive.Portal;
const DialogOverlay = DialogPrimitive.Overlay;
const DialogContent = DialogPrimitive.Content;
const DialogClose = DialogPrimitive.Close;

const { pceBucketName } = browserEnvs;

type Props = {
  type: PreviewType;
  data?: Evidence | null;
};

interface PrismaZoomRefObject {
  getZoom: () => number;
  zoomIn: (zoom: number) => void;
  zoomOut: (zoom: number) => void;
  move: (shiftX: number, shiftY: number, transitionDuration?: number) => void;
  reset: () => void;
  zoomToZone: (relX: number, relY: number, relWidth: number, relHeight: number) => void;
}

const PreviewContent: React.FC<Props> = ({ type, data }) => {
  const photoRef = useRef<PrismaZoomRefObject>(null);
  const [fileAddress, setFileAddress] = useState(data?.tempFileAddress ?? '');

  const handleZoomIn: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    photoRef.current?.zoomIn(1);
  };

  const handleZoomOut: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    photoRef.current?.zoomOut(1);
  };

  const refreshFileAddress = async (evidence: Evidence) => {
    const preSignedUrl = await getPreSignedUrl(evidence, pceBucketName);
    setFileAddress(preSignedUrl);
  };

  useEffect(() => {
    if (!data) return;
    if (data.tempFileAddress) {
      setFileAddress(data.tempFileAddress);
    } else {
      refreshFileAddress(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.tempFileAddress]);

  if (!fileAddress) {
    return null;
  }

  switch (type) {
    case PreviewType.PHOTO: {
      return (
        <>
          <UnstyledButton
            onClick={handleZoomIn}
            className="absolute left-5 top-5 z-10 cursor-pointer rounded-md bg-black/50 p-2.5 text-white/50 backdrop-blur-[5px] transition-colors hover:text-white"
          >
            <ZoomInIcon className="h-6 w-6" />
          </UnstyledButton>
          <UnstyledButton
            onClick={handleZoomOut}
            className="absolute left-5 top-[74px] z-10 cursor-pointer rounded-md bg-black/50 p-2.5 text-white/50 backdrop-blur-[5px] transition-colors hover:text-white"
          >
            <ZoomOutIcon className="h-6 w-6" />
          </UnstyledButton>
          <PrismaZoom ref={photoRef} className="h-full w-full">
            <img
              src={fileAddress}
              alt={data?.fileName ?? ''}
              className="block h-full w-full object-contain object-center"
            />
          </PrismaZoom>
        </>
      );
    }
    case PreviewType.VIDEO: {
      return (
        <video
          controls
          preload="auto"
          autoPlay={false}
          src={fileAddress}
          className="h-full w-full object-contain"
        />
      );
    }
    default: {
      return null;
    }
  }
};

const handleGoToMapView: React.MouseEventHandler = (event) => {
  event.stopPropagation();
  closeEvidencePreview();
  openNestedFlyout(NestedFlyoutType.EVIDENCE_MAP_VIEW);
};

export const EvidencePreview = forwardRef<HTMLDivElement>((props, ref) => {
  const { open, type, current, data, className, closeCallback } =
    useSnapshot(evidencePreviewManager);
  const geolocated = data[current]?.geolocation?.geolocatable ?? false;
  const { color, text } = getGeolocationStatusText(data[current]);

  const handlePreviewClose = () => {
    closeEvidencePreview();
    setTimeout(closeCallback, 150);
  };

  return (
    <DialogRoot open={open} onOpenChange={handlePreviewClose}>
      <DialogPortal>
        <DialogOverlay className="fixed inset-0 z-[999] bg-black data-[state=closed]:animate-fadeOut data-[state=open]:animate-fadeIn" />
        <DialogContent
          className={twMerge(
            'fixed inset-0 z-1000 flex h-screen w-screen items-center justify-center text-white focus:outline-none data-[state=open]:animate-fadeIn',
            className,
          )}
        >
          {geolocated ? (
            <div className="absolute inset-x-12 top-5 z-10 flex items-center justify-center ">
              <UnstyledButton
                full={false}
                onClick={handleGoToMapView}
                className="group inline-flex cursor-pointer items-center gap-x-2 rounded-full bg-black/50 px-4 py-2.5 text-white/50 backdrop-blur-[5px] transition-colors hover:text-white"
              >
                {color === 'text-green' ? (
                  <LocationIcon className="h-4 w-4 text-green/50 transition-colors group-hover:text-green" />
                ) : (
                  <LocationIcon className="h-4 w-4 text-amber/50 transition-colors group-hover:text-amber" />
                )}
                <span className="text-sm font-medium">{text}</span>
                <ArrowRight2Icon className="h-4 w-4" />
              </UnstyledButton>
            </div>
          ) : null}
          <DialogClose className="absolute right-5 top-5 z-10 cursor-pointer rounded-md bg-black/50 p-2.5 text-white/50 backdrop-blur-[5px] transition-colors hover:text-white">
            <CloseIcon className="h-6 w-6" />
          </DialogClose>
          <UnstyledButton
            hidden={current === 0}
            onClick={() => prevEvidence()}
            className="absolute left-5 top-[50%] z-10 cursor-pointer rounded-md bg-black/50 p-2.5 text-white/50 backdrop-blur-[5px] transition-colors hover:text-white"
          >
            <ArrowLeft2Icon className="h-6 w-6" />
          </UnstyledButton>
          <UnstyledButton
            hidden={current === data.length - 1}
            onClick={() => nextEvidence()}
            className="absolute right-5 top-[50%] z-10 cursor-pointer rounded-md bg-black/50 p-2.5 text-white/50 backdrop-blur-[5px] transition-colors hover:text-white"
          >
            <ArrowRight2Icon className="h-6 w-6" />
          </UnstyledButton>
          <PreviewContent type={type} data={data[current]} />
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
});

EvidencePreview.displayName = 'EvidencePreview';
