export const ClaimsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.61943 5.28716C6.22477 2.68429 10.4446 2.68469 13.0468 5.28688L13.0543 5.29432L17.7602 10.0002L13.0466 14.7137C13.0421 14.7182 13.0377 14.7227 13.0333 14.7273C10.4299 17.3163 6.21757 17.3118 3.61971 14.7139C1.01701 12.1112 1.01504 7.88908 3.61943 5.28716ZM14.2394 15.878C14.2348 15.8828 14.23 15.8877 14.2253 15.8924C10.972 19.1456 5.69441 19.1456 2.4412 15.8924C-0.811662 12.6396 -0.815244 7.36172 2.44148 4.10809C5.69456 0.85809 10.9632 0.855002 14.2172 4.10031L14.2252 4.10817L19.5228 9.40585C19.5245 9.40752 19.5262 9.40921 19.5279 9.41091C19.6194 9.50235 19.6851 9.60977 19.7252 9.72417C19.7742 9.86424 19.7848 10.0148 19.7568 10.1594C19.7263 10.3169 19.65 10.4674 19.5279 10.5895C19.5262 10.5912 19.5245 10.5929 19.5228 10.5946L14.2394 15.878ZM8.33317 4.5835C8.79341 4.5835 9.1665 4.95659 9.1665 5.41683V5.8335H9.58317C10.0434 5.8335 10.4165 6.20659 10.4165 6.66683C10.4165 7.12707 10.0434 7.50016 9.58317 7.50016H8.33407L8.33317 7.50016L8.33227 7.50016H7.9165C7.4573 7.50016 7.08317 7.87429 7.08317 8.3335C7.08317 8.7927 7.4573 9.16683 7.9165 9.16683L7.91741 9.16683H8.74984C10.1295 9.16683 11.2498 10.2871 11.2498 11.6668C11.2498 12.9046 10.3482 13.9336 9.1665 14.1322V14.5835C9.1665 15.0437 8.79341 15.4168 8.33317 15.4168C7.87293 15.4168 7.49984 15.0437 7.49984 14.5835V14.1668H7.08317C6.62293 14.1668 6.24984 13.7937 6.24984 13.3335C6.24984 12.8733 6.62293 12.5002 7.08317 12.5002H8.33317H8.74984C9.20904 12.5002 9.58317 12.126 9.58317 11.6668C9.58317 11.2076 9.20904 10.8335 8.74984 10.8335H7.9165C6.53682 10.8335 5.4165 9.71318 5.4165 8.3335C5.4165 7.09576 6.31816 6.06677 7.49984 5.86813V5.41683C7.49984 4.95659 7.87293 4.5835 8.33317 4.5835Z"
        fill="currentColor"
      />
    </svg>
  );
};
