import { userManager } from '@paid-ui/models/user';
import { cva } from 'class-variance-authority';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';

const linkVariants = cva(
  'relative cursor-pointer appearance-none whitespace-nowrap text-white/70 no-underline transition-colors duration-150 ease-linear hover:text-white focus:text-white flex flex-col gap-2 justify-center items-center text-xs',
  {
    variants: {
      active: {
        true: 'after:absolute after:inset-x-0 after:bottom-[-2px] after:h-[2px] after:bg-blue after:content-[""] text-white',
      },
      impersonation: {
        true: '',
      },
    },
    compoundVariants: [
      {
        active: true,
        impersonation: true,
        className: 'after:bg-white/60',
      },
    ],
  },
);

const mobileLinkVariants = cva(
  'relative flex cursor-pointer appearance-none items-center gap-x-3 rounded px-3 py-2.5 font-semibold text-white no-underline transition-colors duration-150 ease-linear',
  {
    variants: {
      active: {
        true: 'bg-blue hover:bg-blue-dark active:bg-blue-dark',
      },
    },
  },
);

interface NavbarLinkProps extends Omit<React.ComponentPropsWithoutRef<typeof Link>, 'href'> {
  href?: string;
  mobile?: boolean;
}

export const NavbarLink: React.FC<NavbarLinkProps> = ({
  href,
  mobile = false,
  hidden,
  className,
  children,
  ...restProps
}) => {
  const pathname = usePathname();
  const active = pathname === href;
  const { impersonation } = useSnapshot(userManager);

  if (hidden || !href) {
    return null;
  }

  if (mobile) {
    return (
      <Link
        href={href}
        className={twMerge(mobileLinkVariants({ active, className }))}
        {...restProps}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link
      href={href}
      className={twMerge(linkVariants({ active, impersonation, className }))}
      {...restProps}
    >
      {children}
    </Link>
  );
};
