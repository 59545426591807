import { userManager } from '@paid-ui/models/user';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';

type Props = {
  side?: 'top' | 'left';
  fullHeight?: boolean;
  fullWidth?: boolean;
  children: React.ReactNode;
};

export const Navbar: React.FC<Props> = ({ side, fullHeight, fullWidth, children }) => {
  const { impersonation } = useSnapshot(userManager);

  if (side === 'top') {
    return (
      <header
        className={twMerge(
          'sticky inset-x-0 top-0 z-50 h-[54px] w-full shrink-0 shadow sm:hidden',
          impersonation ? 'bg-purple' : 'bg-black',
        )}
      >
        <div
          className={twMerge(
            'container mx-auto flex h-full items-center justify-between',
            fullWidth ? 'max-w-full' : '',
          )}
        >
          {children}
        </div>
      </header>
    );
  }

  return (
    <header
      className={twMerge(
        'sticky inset-x-0 top-0 z-50 hidden h-screen w-[54px] shrink-0 shadow sm:block sm:w-[80px]',
        impersonation ? 'bg-purple' : 'bg-black',
      )}
    >
      <div
        className={twMerge(
          'container flex h-full flex-col items-center justify-start',
          fullHeight ? 'max-h-full' : '',
        )}
      >
        {children}
      </div>
    </header>
  );
};
